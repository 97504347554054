import React from 'react';
import './HomePage.css';

import { NavBar } from '../NavBar/NavBar';
import { ContentSection } from '../ContentSection/ContentSection';
import { Footer } from '../Footer/Footer';

export const HomePage = () => {
    
    const content = [
        {
            id: 'about',
            header: 'About Me',
            content: 'Hey, I’m Claire. Welcome to my website!',
            type: 'default'
        },
        {
            id: 'projects',
            header: 'My Projects',
            content: '',
            type: 'card',
            card: [
                {
                    header: 'Follow Me Robot',
                    content: 'A Robot That Follows you around. Codeveloped with a groupe in Uni',
                    link: 'https://github.com/cl-ire/camera_package',
                    link_text: 'View on GitHub'
                },
                {
                    header: 'Robotics UI',
                    content: 'Open Sourece Ros2 webinterface. Releasing soon',
                    link: 'https://github.com/sciotaio/robotics-ui',
                    link_text: 'View on GitHub'
                },
                {
                    header: 'Web Shop',
                    content: 'Uni Project to create a webshop',
                    link: 'https://github.com/cl-ire/ewa_geschenke_shop',
                    link_text: 'View on GitHub'
                },
            ]
        },
        {
            id: 'contact',
            header: 'Contact Me',
            content: '',
            type: 'contact',
            contact: [
                {
                    link: 'https://www.instagram.com/_cl.ire/',
                    link_text: 'Instagram',
                    link_image: 'instagram.png'
                },
                {
                    link: 'https://github.com/cl-ire',
                    link_text: 'Github',
                    link_image: 'github.png'
                },
                {
                    link: 'https://www.linkedin.com/in/claire-schubert-069364328/',
                    link_text: 'Linkedin',
                    link_image: 'linkedin.png'
                }
            ]
        },
    ]
    
    return (
        <div className="HomePage">
            <NavBar />
            {content.map((item) => (
                <ContentSection
                    section_info={item}
                />
            ))}
            <Footer />
        </div>
    );
};